import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col } from "react-bootstrap";
import Popup from "reactjs-popup";
import { TextField, Button } from "@mui/material";
import ExamComponent from "./component/exam_component";
import "./exam_screen.css";
import config from "../../../../../network/auth";
import { baseUrl } from "../../../../../network/constants/constants";
import axios from "../../../../../network/api";
import Box from "@mui/material/Box";
import { MdOutlineFileUpload } from "react-icons/md";
import Accordion from "react-bootstrap/Accordion";

const ExamScreen = ({ onPageChange }) => {
  const [examDetails, setExamDetails] = useState([]);
  const [newInstruction, setNewInstruction] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [newExamDetails, setNewExamDetails] = useState({
    exam_title: "",
    exam_instructions: [],
    exam_duration: "",
    max_score: "",
    exam_solutions: null,
    subject: "",
    chapter: "",
  });
  const [errors, setErrors] = useState({})
  const [selectedFileName, setSelectedFileName] = useState("");
  const validateForm = (examDetails, instructions) => {
    const errors = {};
  
    if (!examDetails.exam_title.trim()) {
      errors.exam_title = "Exam title is required.";
    }
  
  
    const durationRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if (!durationRegex.test(examDetails.exam_duration)) {
      errors.exam_duration =
        "Please enter a valid duration in the format HH:MM:SS.";
    }
  
    if (!examDetails.max_score.trim()) {
      errors.max_score = "Max score is required.";
    } else if (isNaN(examDetails.max_score) || examDetails.max_score <= 0) {
      errors.max_score = "Score must be a positive number.";
    }
  
    if (!examDetails.subject) {
      errors.subject = "Subject is required.";
    }

    if (!examDetails.chapter) {
      errors.subject = "chapter is required.";
    }
  
    if (!examDetails.exam_solutions) {
      errors.exam_solutions = "A PDF file is required.";
    } else if (!examDetails.exam_solutions.name.match(/\.pdf$/)) {
      errors.exam_solutions = "File must be a PDF.";
    }
  
    return errors;
  };
  

  // console.log(newExamDetails.subject)
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setNewExamDetails({
      ...newExamDetails,
      [name]: value,
    });
  };

  const handleInstructionChange = (index, event) => {
    
    const values = [...newInstruction];
    values[index] = event.target.value;
      setErrors((prevErrors) => ({
      ...prevErrors,
      instructions: "",
    }));
    setNewInstruction(values);
  };

  const handleSubjectChange = (e) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      subject: "",
    }));
    setNewExamDetails({
      ...newExamDetails,
      subject: e.target.value,
    });
  };
  const handleChapterChange = (e) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      chapter: "",
    }));
    
    setNewExamDetails({
      ...newExamDetails,
      chapter: e.target.value,
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0]; 
    setNewExamDetails({
      ...newExamDetails,
      exam_solutions: file, 
    });
    setSelectedFileName(file.name);
  };
  

  const handleAddInstruction = () => {
    setNewInstruction([...newInstruction, ""]);
  };

  const handleRemoveInstruction = (index) => {
    const values = [...newInstruction];
    values.splice(index, 1);
    setNewInstruction(values);
  };

  // Function to fetch subject list
  const fetchSubjectList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/courses/`, config)
      .then((response) => {
        const subjects = response.data.results.flatMap((course) =>
          course.course_subjects.map((subject) => ({
            id: subject.subject_id,
            name: subject.Subject_name,
          }))
        );
        setSubjectList(subjects);
      })
      .catch((error) => {
        console.error("Error fetching subject list:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const fetchChapterList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/chapters/`, config)
      .then((response) => {
        const chapterList = response.data.map((chapter) => ({
          id: chapter.id,
          session: chapter.session,
        }));
        setChapterList(chapterList);
      })
      .catch((error) => {
        console.error(
          "Error fetching chapter list:",
          error.response || error.message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Fetch exam
  const fetchExam = (page) => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/exam/`, config)
      .then((response) => {
        setExamDetails(response.data);
        // console.log(response.data)
      })
      .catch((error) => {
        console.error("Fetch Exam Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExam();
    fetchSubjectList();
    fetchChapterList();
  }, []);

  const handleSubmit = (event, close) => {
    event.preventDefault();
    const validationErrors = validateForm(newExamDetails, newInstruction);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    handleAddExamDetails(close);
  };

  // post exam
  const handleAddExamDetails = (close) => {
    const formData = new FormData();
    formData.append("exam_title", newExamDetails.exam_title);
    formData.append(
      "exam_instructions",
      JSON.stringify(newInstruction.filter((instr) => instr !== ""))
    );
    formData.append("exam_duration", newExamDetails.exam_duration);
    formData.append("max_score", newExamDetails.max_score);
    formData.append("subject", newExamDetails.subject);
    formData.append("chapter", newExamDetails.chapter);
    formData.append("exam_solutions", newExamDetails.exam_solutions);
    formData.append("link_type", "ext_link");

    axios
      .post(`${baseUrl}app/exam/upload/`, formData, config)
      .then((response) => {
        setExamDetails([...examDetails, response.data]);
        setNewExamDetails({
          exam_title: "",
          exam_instructions: [],
          exam_duration: "",
          max_score: "",
          subject: "",
          exam_solutions: null,
        });
        setSelectedFileName("");
        setNewInstruction([""]);
        close();
      })
      .catch((error) => {
        console.error("Add Exam Error:", error);
      });
  };

  // put exam
  const handleEditExamDetails = (examId, editedExamDetails, close) => {
    const formData = new FormData();
    formData.append("exam_title", editedExamDetails.exam_title);
    formData.append(
      "exam_instructions",
      JSON.stringify(editedExamDetails.exam_instructions)
    );
    formData.append("exam_duration", editedExamDetails.exam_duration);
    formData.append("max_score", editedExamDetails.max_score);
    formData.append("subject", editedExamDetails.subject);
    formData.append("chapter", editedExamDetails.chapter);
  
    if (editedExamDetails.exam_solutions instanceof File) {
      formData.append("exam_solutions", editedExamDetails.exam_solutions);
    }

    formData.append("link_type", "ext_link");
    axios
      .put(`${baseUrl}app/exam-edit/${examId}/`, formData, config)
      .then((response) => {
        setExamDetails((prevExamDetails) =>
          prevExamDetails.map((exam) =>
            exam.exam_id === examId ? response.data : exam
          )
        );
        setNewExamDetails({
          exam_title: "",
          exam_instructions: [],
          exam_duration: "",
          max_score: "",
          subject: "",
          exam_solutions: null,
        });
        setNewInstruction([""]);
        close();
      })
      .catch((error) => {
        console.error("Edit Exam Error:", error);
      });
  };

  // delete Exam
  const deleteExam = (examId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/exam-delete/${examId}/`, config)
      .then((response) => {
        setExamDetails((prevExam) =>
          prevExam.filter((exam) => exam.id !== examId)
        );
      })
      .catch((error) => {
        console.error("Exam Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExamClick = (examId) => {
    onPageChange("subexam", examId);
  };

  const groupExamBySubject = () => {
    return examDetails.reduce((acc, exam) => {
      const subjectId = exam.subject;
      if (!acc[subjectId]) {
        acc[subjectId] = [];
      }
      acc[subjectId].push(exam);
      return acc;
    }, {});
  };

  const groupedExam = groupExamBySubject();

  // console.log("Grouped Recorded Classes:", groupedRecordedClasses);

  return (
    <div className="main-container">
      <Container fluid>
      <h2 className="accordion-head m-3">Subjects with Exams</h2>
        {loading ? (
          <Spinner className="loader-spinner" />
        ) : Object.keys(groupedExam).length > 0 ? (
          <div>
          <Accordion>
            {Object.keys(groupedExam).map((subjectId, idx) => {
              const subjectName =
                subjectList.find(
                  (subject) => subject.id === parseInt(subjectId)
                )?.name || "Unknown Subject";

              return (
                <Accordion.Item eventKey={idx.toString()} key={subjectId}>
                  <Accordion.Header>{subjectName}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {groupedExam[subjectId].map((exam) => (
                        <Col lg={6} key={exam.exam_id}>
                          <ExamComponent
                            key={exam.id}
                            id={exam.exam_id}
                            title={exam.exam_title}
                            instruction={exam.exam_instructions}
                            duration={exam.exam_duration}
                            score={exam.max_score}
                            subject_name={
                              subjectList.find(
                                (subject) => subject.id === exam.subject
                              )?.name || ""
                            }
                            subjectList={subjectList}
                            subjectId={exam.subject}
                            chapterList={chapterList}
                            chapterId={exam.chapter}
                            solution={exam.exam_solutions}
                            onExamClick={() => handleExamClick(exam.exam_id)}
                            onDelete={deleteExam}
                            onEditExam={handleEditExamDetails}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
          </div>
        ) : (
          <div>No Exam found</div>
        )}

        <Popup
          className="add-popup-container"
          contentStyle={{ borderRadius: "20px" }}
          trigger={<button className="btn-add">+ Add exams</button>}
          modal
          nested
        >
          {(close) => (
            <div className="add-course-div">
              <form onSubmit={(e) => handleSubmit(e, close)}>
                <Box className="popup-box">
                  <div className="heading">Add Exam Details</div>
                  <div className="course-container">
                    <TextField
                      className="textfields"
                      name="exam_title"
                      label="Exam Title"
                      variant="outlined"
                      size="small"
                      value={newExamDetails.exam_title}
                      onChange={handleInputChange}
                      disabled={loading}
                      error={!!errors.exam_title}
                      helperText={errors.exam_title}
                    />

                    <TextField
                      className="textfields"
                      name="exam_duration"
                      label="Exam Duration"
                      variant="outlined"
                      size="small"
                      value={newExamDetails.exam_duration}
                      onChange={handleInputChange}
                      disabled={loading}
                      error={!!errors.exam_duration}
                      helperText={errors.exam_duration}
                    />
                    <TextField
                      className="textfields"
                      name="max_score"
                      label="Max Score"
                      variant="outlined"
                      size="small"
                      value={newExamDetails.max_score}
                      onChange={handleInputChange}
                      disabled={loading}
                      error={!!errors.max_score}
                      helperText={errors.max_score}
                    />
                    <select
                      name="subject"
                      className="form-control"
                      value={newExamDetails.subject}
                      onChange={handleSubjectChange}
                      disabled={loading}
                    >
                      <option value="">Select Subject</option>
                      {subjectList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {errors.subject && <div className="error-text">{errors.subject}</div>}
                    <select
                      name="chapter"
                      className="form-control"
                      value={newExamDetails.chapter}
                      onChange={handleChapterChange}
                      disabled={loading}
                    >
                      <option value="">Select Chapter</option>
                      {chapterList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.session}
                        </option>
                      ))}
                    </select>
                    {errors.chapter && <div className="error-text">{errors.chapter}</div>}
                    <div className="instruction-heading">Exam Instruction</div>
                    {newInstruction.map((instruction, index) => (
                      <div className="wrapper" key={index}>
                        <TextField
                          name="instruction"
                          label="Instruction"
                          variant="outlined"
                          className="planinfo-form-textfield"
                          size="small"
                          value={instruction}
                          onChange={(e) => handleInstructionChange(index, e)}
                        />
                        <span
                          onClick={() => handleRemoveInstruction(index)}
                          className="material-icons remove-icon"
                        >
                          delete_forever
                        </span>
                      </div>
                    ))}
                     {errors.instructions && <div className="error-text">{errors.instructions}</div>}
                    <div className="plan-info-btn-container">
                      <Button
                        className="add-instruction"
                        onClick={handleAddInstruction}
                      >
                        + Add Instruction
                      </Button>
                    </div>
                    <label className="add-file-upload">
                      <input
                        className="add-img-file"
                        name="exam_solutions"
                        type="file"
                        onChange={handleFileChange}
                        disabled={loading}
                      />
                      <MdOutlineFileUpload className="upload-icon" />
                    </label>
                    {selectedFileName && (
                    <div className="file-name-display">
                      Selected File: {selectedFileName}
                    </div>
                  )}
                      {errors.exam_solutions && <div className="error-text">{errors.exam_solutions}</div>}
                  </div>
                  <div className="submit-banner-button-container">
                    <button type="submit" disabled={loading}>
                      Submit
                    </button>
                    <button type="button" onClick={close} disabled={loading}>
                      Cancel
                    </button>
                  </div>
                </Box>
              </form>
            </div>
          )}
        </Popup>
      </Container>
    </div>
  );
};

export default ExamScreen;



