import React, { useState, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import axios from "../../../../network/api";
import "./chapter_order.css";
import { Spinner } from "react-bootstrap";
import ExamRecOrder from "./component/exam_rec_order";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

const SortableItem = ({ id, name, onChapterClick }) => {
  
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false)
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: 16,
    margin: "0 0 8px 0",
    border: "1px dotted #796dec",
    borderRadius: 4,
    color: "#796dec",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  };

const hoverStyle = {
  border: "2px solid #796dec",
  boxShadow: "0 0 5px #796dec",
};

const activeStyle = {
  border: "2px solid #544bce", 
  boxShadow: "0 0 10px #544bce", 
};

// Merging styles conditionally based on state
const finalStyle = {
  ...style,
  ...(isHovered ? hoverStyle : {}),
  ...(isActive ? activeStyle : {}),
};

const handleChapterClick = () => {

  onChapterClick(id);
};
  return (
    <div   ref={setNodeRef}
    style={finalStyle}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    {...attributes}
    {...listeners}
    onClick={handleChapterClick} 
    >
      {/* <div style={{ display: "flex", alignItems: "center" }} onClick={handleChapterClick}>
       view
      </div> */}
      <span>{name}</span>
    </div>
  );
};

const ChapterOrder = ({ subjectId }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [open, setOpen] = useState(false);

  // Fetch subjects
  const fetchSubjects = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/subject-complete-subjectid/${subjectId}/`, config)
      .then((response) => {
        // console.log(response.data.order)
        const order = response.data.order;
        const combinedItems = order.map((item) => ({
          id: item.id,
          name: item.name,
          order: item.order
        }));
        // console.log(combinedItems);
        setItems(combinedItems);
        setLoading(false);
      })
      .catch((error) => {
        // console.error("Subjects Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSubjects();
  }, [subjectId]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const reorderedItems = arrayMove(items, oldIndex, newIndex);
      setItems(reorderedItems);
      console.log(reorderedItems);
      axios
        .post(
          `${baseUrl}app/subject-order-update/${subjectId}/`,
          reorderedItems,
          config
        )
        .then((response) => {
          console.log("Order updated successfully:", response.data);
        })
        .catch((error) => {
          console.error("Failed to update order:", error);
        });
    }
  };

 
  const handleChapterClick = (chapterId) => {
    setSelectedChapterId(chapterId);
    setOpen(true); 
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="order-content">
      {loading ? (
        <Spinner className="loader-spinner"/>
      ) : items.length === 0 ? (
        <p>No chapters available</p>
      ) : (
        <div className="sortable-container">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  onChapterClick={handleChapterClick}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className='rec-exam-order-head mb-2'>Rearrange Recorded classes and Exams</DialogTitle>
        <DialogContent>
        {selectedChapterId && <ExamRecOrder chapterId={selectedChapterId} />}
        </DialogContent>
        <DialogActions>
          <button className='prg-cls' onClick={handleClose}> Close</button>
        </DialogActions>
      </Dialog>
        
    </div>
  );
};

export default ChapterOrder;










