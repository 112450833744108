import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./course_component.css";
import { MdEdit, MdDeleteForever, MdOutlineFileUpload } from "react-icons/md";
import Popup from "reactjs-popup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const CourseComponent = ({
  id,
  name,
  price,
  validity,
  description,
  offer,
  photo,
  onDelete,
  onEditCourse,
  onCourseClick,
}) => {
  const [editingCourse, setEditingCourse] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editedCourse, setEditedCourse] = useState({
    name,
    price,
    validity,
    description,
    offer,
    photo,
  });
  const [errors, setErrors] = useState({});


  const handleCourseChange = (event) => {
    setEditedCourse({
      ...editedCourse,
      [event.target.name]: event.target.value,
    });
    if (errors[event.target.name]) {
      setErrors({
        ...errors,
        [event.target.name]: "",
      });
    }
  
  };

  const handleFileChange = (event) => {
    setEditedCourse({
      ...editedCourse,
      photo: event.target.files[0],
    });
    if (errors.photo) {
      setErrors({
        ...errors,
        photo: "",
      });
    }
  };

 
  const handleConfirmDelete = () => {
    onDelete(id);
    setDeleteConfirmationOpen(false);
  };
  const handleCourseClick = () => {
    onCourseClick();
  };

  const validateFields = () => {
    const newErrors = {};
  
    if (!editedCourse.name || editedCourse.name.length <= 4) {
      newErrors.name = "Course name must be more than 4 characters.";
    }
    if (!editedCourse.price || isNaN(editedCourse.price) || parseFloat(editedCourse.price) <= 0) {
      newErrors.price = "Price must be a valid number greater than 0.";
    }
    const validityDays = parseInt(editedCourse.validity, 10);
    if (isNaN(validityDays) || validityDays < 90) {
      newErrors.validity = "Validity must be at least 90 days.";
    }
    if (!editedCourse.description || editedCourse.description.length <= 12) {
      newErrors.description = "Course description must be more than 12 characters.";
    }
    if (!editedCourse.offer || isNaN(editedCourse.offer)) {
      newErrors.offer = "Offer must be a valid number.";
    }
    if (!editedCourse.photo) {
      newErrors.photo = "Photo is required.";
    }
  
    return newErrors;
  };
  
  const handleSubmitEdit = (event, close) => {
    event.preventDefault();
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    onEditCourse(id, editedCourse);
    setEditingCourse(false);
    close();
  };
  
  return (
    <div>
      <div onClick={handleCourseClick} className="move-to-subject">
        <Card className="course-card">
          <Card.Img className="course-image" src={photo} />

          <hr />
          <Card.Body className="card-texts">
            <Card.Text className="course">
              <Row>
                <Col lg={3} className=" mt-lg-1">
                  <div className="course-span">Course Name:</div>
                </Col>
                <Col lg={9}>
                  <div className=" mt-lg-1">{name}</div>
                </Col>
                <Col lg={3} className=" mt-lg-2">
                  <div className="course-span">Description:</div>
                </Col>
                <Col lg={9}>
                  <div className=" mt-lg-2">{description}</div>
                </Col>
                <Col lg={3} className=" mt-lg-2">
                  <div className="course-span">Validity:</div>
                </Col>
                <Col lg={9}>
                  <div className=" mt-lg-2">{validity}</div>
                </Col>
                <Col lg={3} className=" mt-lg-2">
                  <div className="course-span">Price:</div>
                </Col>
                <Col lg={9}>
                  <div className=" mt-lg-2">{price}</div>
                </Col>
                <Col lg={3} className=" mt-lg-2">
                  <div className="course-span">Offers:</div>
                </Col>
                <Col lg={9}>
                  <div className=" mt-lg-2">{offer}</div>
                </Col>
              </Row>
            </Card.Text>
            <div className="course-edit-delete">
              <Popup
                className="add-popup-container"
                trigger={
                  <button className="course-edit">
                    <MdEdit />
                  </button>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="add-course-div">
                    <form onSubmit={(e) => handleSubmitEdit(e, close)}>
                      <Box className="popup-box">
                        <div className="heading">Edit Course Details</div>
                        <div className="course-container">
                          <TextField
                            className="textfields"
                            name="name"
                            label="Name"
                            variant="outlined"
                            size="small"
                            value={editedCourse.name}
                            onChange={handleCourseChange}
                            error={!!errors.name}
                            helperText={errors.name}
                          />
                          <TextField
                            className="textfields"
                            name="price"
                            label="Price"
                            variant="outlined"
                            size="small"
                            value={editedCourse.price}
                            onChange={handleCourseChange}
                            error={!!errors.price}
                            helperText={errors.price}
                          />
                          <TextField
                            className="textfields"
                            name="validity"
                            label="Validity"
                            variant="outlined"
                            size="small"
                            value={editedCourse.validity}
                            onChange={handleCourseChange}
                            error={!!errors.validity}
  helperText={errors.validity}
                          />
                          <TextField
                            className="textfields"
                            name="offer"
                            label="Offer"
                            variant="outlined"
                            size="small"
                            value={editedCourse.offer}
                            onChange={handleCourseChange}
                            error={!!errors.offer}
                            helperText={errors.offer}
                          />
                          <TextField
                            className="textfields"
                            name="description"
                            label="Description"
                            variant="outlined"
                            multiline
                            rows={3}
                            size="small"
                            value={editedCourse.description}
                            onChange={handleCourseChange}
                            error={!!errors.description}
                            helperText={errors.description}
                          />
                          <label className="add-file-upload">
                            <input
                              className="add-img-file"
                              id="file"
                              type="file"
                              onChange={handleFileChange}
                            />
                            <MdOutlineFileUpload className="upload-icon" />
                          </label>
                          {errors.photo && <div className="error">{errors.photo}</div>}
                        </div>
                        <div className="submit-banner-button-container">
                          <button type="submit">Submit</button>
                          <button type="button" onClick={close}>
                            Cancel
                          </button>
                        </div>
                      </Box>
                    </form>
                  </div>
                )}
              </Popup>

              <Popup
                className="popup-container"
                contentStyle={{
                  borderRadius: "20px",
                  backgroundColor: "white",
                }}
                trigger={
                  <button className="course-delete">
                    <MdDeleteForever />
                  </button>
                }
                modal
                nested
              >
                {(close) => (
                  <div className="delete-img-div">
                    <h3 className="delete-img-heading">Are you sure?</h3>
                    <p className="delete-img-p">
                      Do you really want to delete?
                    </p>
                    <div className="delete-btn-div">
                      <button onClick={handleConfirmDelete}>Delete</button>
                      <button onClick={close}>Cancel</button>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CourseComponent;





