// const ExamRecOrder = ({ chapterId }) => {
    
//     return (
//       <div>
//         <h3>Chapter Details for ID: {chapterId}</h3>
        
//       </div>
//     );
//   };
// export default ExamRecOrder;
  

import React, { useState, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import "./exam_rec_order.css";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import QuizIcon from "@mui/icons-material/Quiz";
import { Spinner } from "react-bootstrap";

const SortableItem = ({ id, content, type }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false)
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: 16,
    margin: "0 0 8px 0",
    border: "1px dotted #796dec",
    borderRadius: 4,
    color: "#796dec",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-arround",
  };

const hoverStyle = {
  border: "2px solid #796dec",
  boxShadow: "0 0 5px #796dec",
};

const activeStyle = {
  border: "2px solid #544bce", 
  boxShadow: "0 0 10px #544bce", 
};

// Merging styles conditionally based on state
const finalStyle = {
  ...style,
  ...(isHovered ? hoverStyle : {}),
  ...(isActive ? activeStyle : {}),
};

  return (
    <div ref={setNodeRef}   style={finalStyle}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onMouseDown={() => setIsActive(true)}
    onMouseUp={() => setIsActive(false)} {...attributes} {...listeners}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {type === "Exam" ? (
          <QuizIcon style={{ marginRight: 8 }} />
        ) : (
          <VideoLibraryIcon style={{ marginRight: 8 }} />
        )}
      </div>
      <span>{content}</span>
    </div>
  );
};

const SortableList = ({ chapterId }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch subjects
  const fetchExamRec = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/chapter-by-id/${chapterId}/`, config)
      .then((response) => {
    
        const order = response.data.order;
        const combinedItems = order.map((item) => ({
          id: item.id,
          content: item.content,
          type: item.type,
          order: item.order
        }));
        // console.log(combinedItems);
        setItems(combinedItems);
        setLoading(false);
        // console.log(response.data);
      })
      .catch((error) => {
        // console.error("Subjects Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExamRec();
  }, [chapterId]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const reorderedItems = arrayMove(items, oldIndex, newIndex);
      setItems(reorderedItems);
      console.log(reorderedItems);
      axios
        .post(
          `${baseUrl}app/chapter-order-update/${chapterId}/`,
          reorderedItems,
          config
        )
        .then((response) => {
          console.log("Order updated successfully:", response.data);
        })
        .catch((error) => {
          console.error("Failed to update order:", error);
        });
    }
  };

  return (
    <div className="order-content">
      {loading ? (
        <Spinner/>
      ) : (
        <div className="order-container">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  content={item.content}
                  type={item.type}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      )}
    </div>
  );
};

export default SortableList;

