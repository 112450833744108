import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Spinner } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { MdDeleteForever, MdOutlineFileUpload } from "react-icons/md";
import Popup from "reactjs-popup";
import { baseUrl } from "../../../../network/constants/constants";
import axios from "../../../../network/api";
import config from "../../../../network/auth";
import "./banner.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";

const Banner = () => {
  const [bannerDetails, setBannerDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newBanner, setNewBanner] = useState({ title: "", image: null });
  const [errors, setErrors] = useState({ title: "", image: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Fetch banners
  const fetchGallery = (page) => {
    setLoading(true);
    axios
      .get(baseUrl + `app/banner/list/?page=${page}`, config)
      .then((response) => {
        setLoading(false);
        setBannerDetails(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(page);
      })
      .catch((error) => {
        console.error("Banner details Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGallery(currentPage);
  }, [currentPage]);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      fetchGallery(pageNumber);
    }
  };

  const renderPaginationControls = () => {
    let paginationHtml = [];

    // previous button
    if (currentPage > 1) {
      paginationHtml.push(
        <button key="prev" onClick={() => goToPage(currentPage - 1)}>
          <ArrowBackIosIcon />
        </button>
      );
    }

    // page numbers
    for (let i = 1; i <= totalPages; i++) {
      if (i === currentPage) {
        paginationHtml.push(<span key={i}>{i}</span>);
      } else {
        paginationHtml.push(
          <button key={i} onClick={() => goToPage(i)}>
            {i}
          </button>
        );
      }
    }

    // next button
    if (currentPage < totalPages) {
      paginationHtml.push(
        <button key="next" onClick={() => goToPage(currentPage + 1)}>
          <ArrowForwardIosIcon />
        </button>
      );
    }

    return paginationHtml;
  };

  // Validate form
  const validateForm = () => {
    let titleError = "";
    let imageError = "";

    if (newBanner.title.length < 4) {
      titleError = "Banner title must be at least 4 characters long";
    }

    if (!newBanner.image) {
      imageError = "Banner image is required";
    }

    if (titleError || imageError) {
      setErrors({ title: titleError, image: imageError });
      return false;
    }

    setErrors({ title: "", image: "" });
    return true;
  };

  // Post banner
  const handleAddBanner = (e, close) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append("title", newBanner.title);
    formData.append("image", newBanner.image);
    formData.append("link_type", "ext_link");

    axios
      .post(baseUrl + `app/banner/create/`, formData, config)
      .then((response) => {
        setBannerDetails([...bannerDetails, response.data]);
        setNewBanner({ title: "", image: null });
        close();
      })
      .catch((error) => {
        console.error("Add Banner Error:", error);
      });
  };

  // Delete banner
  const handleDeleteBanner = (id) => {
    axios
      .delete(baseUrl + `app/banner/delete/${id}/`, config)
      .then(() => {
        setBannerDetails(bannerDetails.filter((banner) => banner.id !== id));
      })
      .catch((error) => {
        console.error("Delete Banner Error:", error);
      });
  };

  return (
    <div className="main-container">
      <Popup
        className="add-popup-container"
        contentStyle={{ borderRadius: "20px" }}
        trigger={<button className="btn-add">+ Add Banner Details</button>}
        modal
        nested
      >
        {(close) => (
          <div className="add-img-div">
            <form onSubmit={(e) => handleAddBanner(e, close)}>
              <Box className="popup-box">
              <h3 className="add-img-heading">Add Banner Details</h3>
              <div className="banner-details">
                <label className="add-file-upload">
                  <input
                    className="add-img-file"
                    type="file"
                    onChange={(e) =>
                      setNewBanner({ ...newBanner, image: e.target.files[0] })
                    }
                    name="banner_image" 
                    required
                  />
                  <MdOutlineFileUpload className="upload-icon" />
                </label>
                {errors.image && (
                  <div className="error-message">{errors.image}</div>
                )}
                <TextField
                  name="banner_title" 
                  label="Banner Title"
                  variant="outlined"
                  multiline
                  rows={2}
                  size="small"
                  value={newBanner.title}
                  onChange={(e) =>
                    setNewBanner({ ...newBanner, title: e.target.value })
                  }
                  required
                />
                {errors.title && (
                  <div className="error-message">{errors.title}</div>
                )}
              </div>
              <div className="submit-banner-button-container">
                <button type="submit">Upload</button>
                <button type="button" onClick={close}>
                  Cancel
                </button>
              </div>
              </Box>
            </form>
          </div>
        )}
      </Popup>

      <Card className="banner-card">
        <Container>
          {loading ? (
            <Spinner className="loader-spinner" />
          ) : bannerDetails.length === 0 ? (
            <div className="no-banners">
              Currently, no banners are available
            </div>
          ) : (
            <div className="mb-4">
              <Row className="g-4">
                {bannerDetails.map((banner) => (
                  <Col lg={3} key={banner.id}>
                    <Card>
                      <Card.Img
                        variant="top"
                        src={banner.image}
                        alt="banner"
                        className="img-fluid"
                      />
                      <Card.Body>
                        <Card.Text>{banner.title}</Card.Text>
                        <div className="banner-edit-delete-div">
                          <Popup
                            className="popup-container"
                            contentStyle={{
                              borderRadius: "20px",
                              backgroundColor: "white",
                            }}
                            trigger={
                              <button className="banner-delete">
                                <MdDeleteForever />
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="delete-img-div">
                                <h3 className="delete-img-heading">
                                  Are you sure?
                                </h3>
                                <p className="delete-img-p">
                                  Do you really want to delete?
                                </p>
                                <div className="delete-btn-div">
                                  <button
                                    onClick={() => {
                                      handleDeleteBanner(banner.id);
                                      close();
                                    }}
                                  >
                                    Delete
                                  </button>
                                  <button onClick={close}>Cancel</button>
                                </div>
                              </div>
                            )}
                          </Popup>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {totalPages > 1 && (
            <div className="pagination-container">
              <div id="pagination">{renderPaginationControls()}</div>
            </div>
          )}
        </Container>
      </Card>
    </div>
  );
};

export default Banner;
