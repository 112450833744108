import AppConstants from "../components/AppConstants";

let authToken = localStorage.getItem(AppConstants.JWT);
console.log(authToken)
const config = {
    headers: {Authorization: `Token ${authToken}`}
  };
  
export default config;

