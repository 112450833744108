import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import CourseComponent from "./component/course_component/course_component";
import Popup from "reactjs-popup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { MdOutlineFileUpload } from "react-icons/md";
import "./course_screen.css";
import axios from "../../../../../network/api";
import config from "../../../../../network/auth";
import { baseUrl } from "../../../../../network/constants/constants";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Spinner } from "react-bootstrap";

const CourseScreen = ({ onPageChange }) => {
  const [loading, setLoading] = useState(false);
  const [courseDetails, setCourseDetails] = useState([]);
  const [newCourse, setNewCourse] = useState({
    course_name: "",
    course_price: "",
    course_validity: "",
    course_offer: "",
    course_description: "",
    course_photo: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [offerError, setOfferError] = useState("");
  const [validityError, setValidityError] = useState("");
  const [imageError, setImageError] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleCourseClick = (courseId) => {
    onPageChange("subject", courseId);
  };

  const validateForm = () => {
    let isValid = true;

    if (newCourse.course_name.length <= 4) {
      setNameError("Course name must be more than 4 characters.");
      isValid = false;
    }
    if (newCourse.course_description.length <= 12) {
      setDescriptionError(
        "Course description must be more than 12 characters."
      );
      isValid = false;
    }
    const validityDays = parseInt(newCourse.course_validity, 10);
    if (isNaN(validityDays) || validityDays < 90) {
      setValidityError("Validity must be at least 90 days.");
      isValid = false;
    }
    if (
      isNaN(newCourse.course_price) ||
      parseFloat(newCourse.course_price) <= 0
    ) {
      setPriceError("Price must be a valid number.");
      isValid = false;
    }
    if (isNaN(newCourse.course_offer)) {
      setOfferError("Offers must be a valid number.");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (event, close) => {
    event.preventDefault();
    if (validateForm()) {
      handleAddCourse(close);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    
    if (type === "file") {
      const file = files[0];
      if (file) {
        const isImage = file.type.startsWith("image/");
        const isValidSize = file.size <= 5 * 1024 * 1024; 
        
        if (!isImage) {
          setImageError("File must be an image.");
          return;
        }
        if (!isValidSize) {
          setImageError("Image file must be smaller than 5MB.");
          return;
        }
        
        setImageError(""); 
        setNewCourse({
          ...newCourse,
          course_photo: file,
        });
        setSelectedFileName(file.name);
      }
    } else {
      setNewCourse({
        ...newCourse,
        [name]: value,
      });
     
      if (name === "course_name") setNameError("");
      if (name === "course_description") setDescriptionError("");
      if (name === "course_price") setPriceError("");
      if (name === "course_offer") setOfferError("");
      if (name === "course_validity") setValidityError("");
    }
  };
  

  const deleteCourse = (courseId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/courses-delete/${courseId}/`, config)
      .then((response) => {
        setCourseDetails((prevCourse) =>
          prevCourse.filter((course) => course.id !== courseId)
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Course Error:", error);
        setLoading(false);
      });
  };

  const handleAddCourse = (close) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("course_name", newCourse.course_name);
    formData.append("course_price", newCourse.course_price);
    formData.append("course_validity", newCourse.course_validity);
    formData.append("course_offer", newCourse.course_offer);
    formData.append("course_description", newCourse.course_description);
    formData.append("course_photo", newCourse.course_photo);
    formData.append("link_type", "ext_link");

    axios
      .post(baseUrl + `app/courses/upload/`, formData, config)
      .then((response) => {
        fetchCourse(currentPage);
        setNewCourse({
          course_name: "",
          course_price: "",
          course_validity: "",
          course_offer: "",
          course_description: "",
          course_photo: null,
        });
        selectedFileName("")
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("Add Course Error:", error);
        setLoading(false);
      });
  };

  const handleEditCourse = (courseId, editedCourse, close) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("course_name", editedCourse.name);
    formData.append("course_price", editedCourse.price);
    formData.append("course_validity", editedCourse.validity);
    formData.append("course_offer", editedCourse.offer);
    formData.append("course_description", editedCourse.description);
    if (editedCourse.photo instanceof File) {
      formData.append("course_photo", editedCourse.photo);
    }
    formData.append("link_type", "ext_link");

    axios
      .put(baseUrl + `app/courses-edit/${courseId}/`, formData, config)
      .then((response) => {
        fetchCourse(currentPage);
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("Edit Course Error:", error);
        setLoading(false);
      });
  };

  const fetchCourse = (page) => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/courses/?page=${page}`, config)
      .then((response) => {
        setCourseDetails(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Course details Error:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchCourse(currentPage);
  }, [currentPage]);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      fetchCourse(pageNumber);
    }
  };
  const renderPaginationControls = () => {
    let paginationHtml = [];

    // previous button
    if (currentPage > 1) {
      paginationHtml.push(
        <button key="prev" onClick={() => goToPage(currentPage - 1)}>
          <ArrowBackIosIcon />
        </button>
      );
    }

    // page numbers
    for (let i = 1; i <= totalPages; i++) {
      if (i === currentPage) {
        paginationHtml.push(<span key={i}>{i}</span>);
      } else {
        paginationHtml.push(
          <button key={i} onClick={() => goToPage(i)}>
            {i}
          </button>
        );
      }
    }

    // next button
    if (currentPage < totalPages) {
      paginationHtml.push(
        <button key="next" onClick={() => goToPage(currentPage + 1)}>
          <ArrowForwardIosIcon />
        </button>
      );
    }

    return paginationHtml;
  };

  return (
    <div className="main-container">
      <Container fluid>
        {loading ? (
          <Spinner className="loader-spinner" />
        ) : (
          <div className="course-screen-container">
            {courseDetails.map((course) => (
              <CourseComponent
                id={course.course_id}
                key={course.course_id}
                name={course.course_name}
                price={course.course_price}
                validity={course.course_validity}
                description={course.course_description}
                offer={course.course_offer}
                photo={course.course_photo}
                onEditCourse={handleEditCourse}
                onDelete={deleteCourse}
                onCourseClick={() => handleCourseClick(course.course_id)}
              />
            ))}
            <Popup
              className="add-popup-container"
              trigger={
                <button className="btn-add">+ Add Course Details</button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="add-course-div">
                  <form onSubmit={(e) => handleSubmit(e, close)}>
                    <Box className="popup-box">
                      <div className="heading">Add Course Details</div>
                      <div className="course-container">
                        <TextField
                          className="textfields"
                          name="course_name"
                          label="Name"
                          variant="outlined"
                          size="small"
                          value={newCourse.course_name}
                          onChange={handleInputChange}
                          error={!!nameError}
                          helperText={nameError}
                        />
                        <TextField
                          className="textfields"
                          name="course_price"
                          label="Price"
                          variant="outlined"
                          size="small"
                          value={newCourse.course_price}
                          onChange={handleInputChange}
                          error={!!priceError}
                          helperText={priceError}
                        />
                        <TextField
                          className="textfields"
                          name="course_validity"
                          label="Validity"
                          variant="outlined"
                          size="small"
                          value={newCourse.course_validity}
                          onChange={handleInputChange}
                          error={!!validityError}
                          helperText={validityError}
                        />
                        <TextField
                          className="textfields"
                          name="course_offer"
                          label="Offers"
                          id="outlined-number"
                          size="small"
                          value={newCourse.course_offer}
                          onChange={handleInputChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={!!offerError}
                          helperText={offerError}
                        />
                        <TextField
                          className="textfields"
                          name="course_description"
                          label="Description"
                          variant="outlined"
                          multiline
                          rows={3}
                          size="small"
                          value={newCourse.course_description}
                          onChange={handleInputChange}
                          error={!!descriptionError}
                          helperText={descriptionError}
                        />
                        <label className="add-file-upload">
                          <input
                            className="add-img-file"
                            id="file"
                            type="file"
                            onChange={handleInputChange
                            }
                          />
                          <MdOutlineFileUpload className="upload-icon" />
                        </label>
                        {selectedFileName && (
                    <div className="file-name-display">
                      Selected File: {selectedFileName}
                    </div>
                  )}
                        {imageError && <div className="image-error">{imageError}</div>}
                      </div>
                      <div className="submit-banner-button-container">
                        <button type="submit">Submit</button>
                        <button type="button" onClick={close}>
                          Cancel
                        </button>
                      </div>
                    </Box>
                  </form>
                </div>
              )}
            </Popup>
          </div>
        )}
        {totalPages > 1 && (
          <div className="pagination-container">
            <div id="pagination">{renderPaginationControls()}</div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default CourseScreen;
