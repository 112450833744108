import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./subject_component.css";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Popup from "reactjs-popup";
// import Order from "../../../order/order";
import { MdOutlineFileUpload } from "react-icons/md";
import ChapterOrder from "../../../chapter_order/chapter_order";

const SubjectComponent = ({
  id,
  Subject_name,
  Subject_description,
  subject_faculty_name,
  video_url,
  order,
  courseId,
  facultyId,
  facultyList,
  onEditSubject,
  onDelete,
  onFacultyChange,
}) => {
  const [editingSubject, setEditingSubject] = useState(false);
  const [editedSubject, setEditedSubject] = useState({
    Subject_name,
    Subject_description,
    subject_faculty_name,
    course: courseId,
    faculty: facultyId,
    video_url: null,
  });
  const [errors, setErrors] = useState({
    Subject_name: "",
    Subject_description: "",
    faculty: "",
    video_url: "",
  });

  useEffect(() => {
    setEditedSubject({
      Subject_name,
      Subject_description,
      subject_faculty_name,
      course: courseId,
      faculty: facultyId,
      video_url: null,
    });
  }, [
    Subject_name,
    Subject_description,
    subject_faculty_name,
    video_url,
    courseId,
    facultyId,
  ]);

  const validateForm = () => {
    const newErrors = {
      Subject_name: "",
      Subject_description: "",
      faculty: "",
      video_url: "",
    };

    if (!editedSubject.Subject_name) {
      newErrors.Subject_name = "Subject name is required.";
    }
    if (!editedSubject.faculty) {
      newErrors.faculty = "Faculty is required.";
    }
    if (editedSubject.Subject_description.length < 8) {
      newErrors.Subject_description = "Description must be at least 8 characters.";
    }
    if (editedSubject.video_url && editedSubject.video_url.type !== "video/mp4") {
      newErrors.video_url = "Video file must be in MP4 format.";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };
  const handleCourseChange = (event) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setEditedSubject({
      ...editedSubject,
      [name]: value,
    });
  };
  const handleFileInputChange = (e) => {
    const { name, files } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      video_url: "",
    }));
    
    setEditedSubject((prev) => ({
      ...prev,
      [name]: files[0],
    }));
  };
  const handleSubmitEdit = (event, close) => {
    event.preventDefault();
    if (validateForm()) {
      onEditSubject(id, editedSubject, close);
      setEditingSubject(false);
    }
  };

  const handleConfirmDelete = () => {
    onDelete(id);
  };

  return (
    <div>
      <Card className="subject-card">
        <Card.Body className="card-texts">
          <Card.Text className="subject">
            <Row>
              <Col lg={2} className=" mt-lg-1">
                <div className="subject-span">Subject Name:</div>
              </Col>
              <Col lg={10}>
                <div className=" mt-lg-1">{Subject_name}</div>
              </Col>
              <Col lg={2} className=" mt-lg-2">
                <div className="subject-span">Description:</div>
              </Col>
              <Col lg={10}>
                <div className=" mt-lg-2">{Subject_description}</div>
              </Col>
              <Col lg={2} className=" mt-lg-2">
                <div className="subject-span">Faculty Name:</div>
              </Col>
              <Col lg={10}>
                <div className=" mt-lg-2">{subject_faculty_name}</div>
              </Col>
              <Col lg={2} className=" mt-lg-3">
                <div className="subject-span">Video:</div>
              </Col>
              <Col lg={10}>
                <div className=" mt-lg-2">
                  <a href={video_url}>{video_url}</a>
                </div>
              </Col>
            </Row>
          </Card.Text>
          <div className="subject-edit-delete">
            <Popup
              className="add-popup-container"
              trigger={
                <button className="course-edit">
                  <MdEdit />
                </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="add-course-div">
                  <form onSubmit={(e) => handleSubmitEdit(e, close)}>
                    <Box>
                      <div className="heading">Edit Subject Details</div>
                      <div className="course-container">
                        <TextField
                          className="textfields"
                          name="Subject_name"
                          label="Name"
                          variant="outlined"
                          size="small"
                          value={editedSubject.Subject_name}
                          onChange={handleCourseChange}
                          error={Boolean(errors.Subject_name)}
                          helperText={errors.Subject_name}
                        />
                        <select
                          name="faculty"
                          className="form-control"
                          value={editedSubject.faculty}
                          onChange={handleCourseChange}
                        >
                          <option value="">Select Faculty</option>
                          {facultyList.map((faculty) => (
                            <option
                              key={faculty.faculty_id}
                              value={faculty.faculty_id}
                            >
                              {faculty.faculty_name}
                            </option>
                          ))}
                        </select>
                        {errors.faculty && (
                          <div id="faculty-helper-text" className="error-text">
                            {errors.faculty}
                          </div>
                        )}
                        <TextField
                          className="textfields"
                          name="Subject_description"
                          label="Description"
                          variant="outlined"
                          multiline
                          rows={3}
                          size="small"
                          value={editedSubject.Subject_description}
                          onChange={handleCourseChange}
                          error={Boolean(errors.Subject_description)}
                          helperText={errors.Subject_description}
                        />
                        <label className="add-file-upload">
                          <input
                            className="add-img-file"
                            type="file"
                            name="video_url"
                            onChange={handleFileInputChange}
                          />
                          <MdOutlineFileUpload className="upload-icon" />
                        </label>
                        {errors.video_url && (
                          <div className="error-text">{errors.video_url}</div>
                        )}
                      </div>
                      <div className="submit-banner-button-container">
                        <button type="submit">Submit</button>
                        <button type="button" onClick={close}>
                          Cancel
                        </button>
                      </div>
                    </Box>
                  </form>
                </div>
              )}
            </Popup>

            <Popup
              className="popup-container"
              contentStyle={{
                borderRadius: "20px",
                backgroundColor: "white",
              }}
              trigger={
                <button className="course-delete">
                  <MdDeleteForever />
                </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="delete-img-div">
                  <h3 className="delete-img-heading">Are you sure?</h3>
                  <p className="delete-img-p">Do you really want to delete?</p>
                  <div className="delete-btn-div">
                    <button onClick={handleConfirmDelete}>Delete</button>
                    <button onClick={close}>Cancel</button>
                  </div>
                </div>
              )}
            </Popup>
          </div>
          <div>
            <div className="chapter-order-heading mb-3">Rearrange Chapters</div>
            <ChapterOrder courseId={courseId} subjectId={id}/>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SubjectComponent;



