import React, { useState } from "react";
import "./rec_cls_component.css";
import { Card, Row, Col } from "react-bootstrap";
import { MdEdit, MdDeleteForever, MdOutlineFileUpload } from "react-icons/md";
import Popup from "reactjs-popup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const RecordedClsComponent = ({
  recorded_class_id,
  recorded_class_name,
  recorded_class_duration,
  recorded_class_video,
  subject_name,
  subjectId,
  onEditRecVideo,
  subjectList,
  chapterId,
  chapterList,
  onDelete,
}) => {
  const [editingRecVideo, setEditingRecVideo] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editedRecVideo, setEditedRecVideo] = useState({
    recorded_class_name: recorded_class_name,
    recorded_class_duration: recorded_class_duration,
    recorded_class_video: recorded_class_video,
    subject_name: subject_name,
    subject: subjectId,
    chapter: chapterId,
  });
  const [validationErrors, setValidationErrors] = useState({
    recorded_class_name: "",
    recorded_class_duration: "",
    recorded_class_video: "",
    subject: "",
    chapter: "",
  });
  const [selectedFileName, setSelectedFileName] = useState("");
  const durationRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

  const validate = () => {
    let errors = {};

    if (!editedRecVideo.recorded_class_name.trim()) {
      errors.recorded_class_name = "Class name is required";
    }

    if (!editedRecVideo.recorded_class_duration.trim()) {
      errors.recorded_class_duration = "Class duration is required";
    } else if (!durationRegex.test(editedRecVideo.recorded_class_duration)) {
      errors.recorded_class_duration =
        "Please enter a valid duration in the format HH:MM:SS.";
    }

    if (!editedRecVideo.subject) {
      errors.subject = "Subject is required";
    }
    if (!editedRecVideo.chapter) {
      errors.subject = "Chapter is required";
    }

    if (!editedRecVideo.recorded_class_video) {
      errors.recorded_class_video = "Video file is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const chapter = chapterList.find((chapter) => chapter.id === parseInt(chapterId))
 const chapterName = chapter ?chapter.session : "Unknown session";

  // console.log(editedRecVideo)
  const handleRecVideoChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setEditedRecVideo({
      ...editedRecVideo,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; 
    setEditedRecVideo({
      ...editedRecVideo,
      recorded_class_video: event.target.files[0],
    });

    setEditedRecVideo({
      ...editedRecVideo,
      recorded_class_video: file,
    });
    setSelectedFileName(file.name);
  };

  const handleSubmitEdit = (event, close) => {
    event.preventDefault();
    if (validate()) {
      onEditRecVideo(recorded_class_id, editedRecVideo, close);
      setEditingRecVideo(false);
    }
  };

  const handleSubjectChange = (e) => {
    setValidationErrors({
      ...validationErrors,
      subject: "",
    });
    setEditedRecVideo({
      ...editedRecVideo,
      subject: e.target.value,
    });
  };
  const handleChapterChange = (e) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      chapter: "",
    }));
    
    setEditedRecVideo({
      ...editedRecVideo,
      chapter: e.target.value,
    });
  };
  const handleConfirmDelete = () => {
    onDelete(recorded_class_id);
    setDeleteConfirmationOpen(false);
  };
  const url = recorded_class_video;

  const [pdfUrl] = url.split("?");

  const trimFrom = 40;
  const trimTo = 100;

  const trimmedPdfUrl =
    pdfUrl.length > trimTo
      ? pdfUrl.slice(0, trimFrom) + "..." + pdfUrl.slice(trimTo)
      : pdfUrl;

  return (
    <div>
      <Card className="record-card m-2">
        <Card.Body className="rec-card-texts">
          <Card.Text className="recorded">
            <Row>
              <Col lg={4} className=" mt-lg-1">
                <div className="subject-span">Recorded Class video:</div>
              </Col>
              <Col lg={8}>
                <div className=" mt-lg-1">
                  <a href={recorded_class_video}>{trimmedPdfUrl}</a>
                </div>
              </Col>
              <Col lg={4} className=" mt-lg-1">
                <div className="subject-span">Recorded Class Name:</div>
              </Col>
              <Col lg={8}>
                <div className=" mt-lg-1">{recorded_class_name}</div>
              </Col>
              <Col lg={4} className=" mt-lg-2">
                <div className="subject-span">Recorded Class Duration:</div>
              </Col>
              <Col lg={8}>
                <div className=" mt-lg-2">{recorded_class_duration}</div>
              </Col>
              <Col lg={4} className=" mt-lg-2">
                <div className="subject-span">Subject Name:</div>
              </Col>
              <Col lg={8}>
                <div className=" mt-lg-2">{subject_name}</div>
              </Col>
              <Col lg={4} className=" mt-lg-2">
                <div className="subject-span">Chapter Name:</div>
              </Col>
              <Col lg={8}>
                <div className=" mt-lg-2">{chapterName}</div>
              </Col>
            </Row>
          </Card.Text>
          <div className="recorded-edit-delete">
            <Popup
              className="add-popup-container"
              trigger={
                <button className="faculty-edit">
                  <MdEdit />
                </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="add-course-div">
                  <form onSubmit={(e) => handleSubmitEdit(e, close)}>
                    <Box className="popup-box">
                      <div className="heading">Edit Recorded Class Details</div>
                      <div className="course-container">
                        <TextField
                          className="textfields"
                          name="recorded_class_name"
                          label="Class Name"
                          variant="outlined"
                          size="small"
                          value={editedRecVideo.recorded_class_name}
                          onChange={handleRecVideoChange}
                          error={!!validationErrors.recorded_class_name}
                          helperText={validationErrors.recorded_class_name}
                        />
                        <TextField
                          className="textfields"
                          name="recorded_class_duration"
                          label="Class Duration"
                          variant="outlined"
                          size="small"
                          value={editedRecVideo.recorded_class_duration}
                          onChange={handleRecVideoChange}
                          error={!!validationErrors.recorded_class_duration}
                          helperText={validationErrors.recorded_class_duration}
                        />
                        <select
                          name="subject"
                          className="form-control"
                          value={editedRecVideo.subject}
                          onChange={handleSubjectChange}
                        >
                          <option value="">Select Subject</option>
                          {subjectList.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        {validationErrors.subject && (
                          <div className="error-message">
                            {validationErrors.subject}
                          </div>
                        )}
                         <select
                          name="chapter"
                          className="form-control"
                          value={editedRecVideo.chapter}
                          onChange={handleChapterChange}
                        >
                          <option value="">Select Chapter</option>
                          {chapterList.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.session}
                            </option>
                          ))}
                        </select>
                        {validationErrors.chapter && (
                          <div className="error-message">
                            {validationErrors.chapter}
                          </div>
                        )}
                        <label className="add-file-upload">
                          <input
                            className="add-img-file"
                            id="file"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <MdOutlineFileUpload className="upload-icon" />
                        </label>
                        {selectedFileName && (
                    <div className="file-name-display">
                      Selected File: {selectedFileName}
                    </div>
                  )}
                        {validationErrors.recorded_class_video && (
                          <div className="error-message">
                            {validationErrors.recorded_class_video}
                          </div>
                        )}
                      </div>
                      <div className="submit-banner-button-container">
                        <button type="submit">Submit</button>
                        <button type="button" onClick={close}>
                          Cancel
                        </button>
                      </div>
                    </Box>
                  </form>
                </div>
              )}
            </Popup>

            <Popup
              className="popup-container"
              contentStyle={{
                borderRadius: "20px",
                backgroundColor: "white",
              }}
              trigger={
                <button className="recorded-delete">
                  <MdDeleteForever />
                </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="delete-img-div">
                  <h3 className="delete-img-heading">Are you sure?</h3>
                  <p className="delete-img-p">Do you really want to delete?</p>
                  <div className="delete-btn-div">
                    <button onClick={handleConfirmDelete}>Delete</button>
                    <button onClick={close}>Cancel</button>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RecordedClsComponent;
