import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Popup from "reactjs-popup";
import "./subject_screen.css";
import SubjectComponent from "./component/subject_component";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { MdOutlineFileUpload } from "react-icons/md";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const SubjectScreen = ({ onPageChange, courseId }) => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [facultyId, setFacultyId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [newSubject, setNewSubject] = useState({
    Subject_name: "",
    subject_faculty_name: "",
    Subject_description: "",
    course: courseId,
    faculty: "",
    video_url: null,
  });
  const [facultyList, setFacultyList] = useState([]);
  // const [updateFlag, setUpdateFlag] = useState(0);
  // const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    Subject_name: "",
    subject_faculty_name: "",
    Subject_description: "",
    video_url: "",
  });

  const validateSubject = () => {
    const errors = {};
    if (!newSubject.Subject_name)
      errors.Subject_name = "Subject name is required";
    if (!facultyId) errors.subject_faculty_name = "Faculty is required";
    if (!newSubject.Subject_description) {
      errors.Subject_description = "Description is required";
    } else if (newSubject.Subject_description.length < 8) {
      errors.Subject_description =
        "Description must be at least 8 characters long";
    }
    if (newSubject.video_url && !newSubject.video_url.name.endsWith(".mp4"))
      errors.video_url = "Invalid video file type";

    return errors;
  };

  // Function to fetch faculty list
  const fetchFacultyList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/faculties/`, config)
      .then((response) => {
        setFacultyList(response.data.results);
      })
      .catch((error) => {
        console.error("Error fetching faculty list:", error);
        // setError("Failed to fetch faculty list");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to fetch subjects
  const fetchSubjects = (page) => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/subject-list-courseid/${courseId}/?page=${page}`, config)
      .then((response) => {
        setSubjects(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(page);
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error);
        // setError("Failed to fetch subjects");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFacultyList();
    fetchSubjects(currentPage);
  }, [courseId, currentPage]);


  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      fetchSubjects(pageNumber);
    }
  };

  const renderPaginationControls = () => {
    let paginationHtml = [];

    // previous button
    if (currentPage > 1) {
      paginationHtml.push(
        <button key="prev" onClick={() => goToPage(currentPage - 1)}>
          <ArrowBackIosIcon />
        </button>
      );
    }

    // page numbers
    for (let i = 1; i <= totalPages; i++) {
      if (i === currentPage) {
        paginationHtml.push(<span key={i}>{i}</span>);
      } else {
        paginationHtml.push(
          <button key={i} onClick={() => goToPage(i)}>
            {i}
          </button>
        );
      }
    }

    // next button
    if (currentPage < totalPages) {
      paginationHtml.push(
        <button key="next" onClick={() => goToPage(currentPage + 1)}>
          <ArrowForwardIosIcon />
        </button>
      );
    }

    return paginationHtml;
  };

  const handleSubmit = (event, close) => {
    event.preventDefault();
    const errors = validateSubject();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      handleAddSubject(close);
    }
  };
  

  // handle adding a subject
  const handleAddSubject = (close) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("Subject_name", newSubject.Subject_name);
    formData.append("subject_faculty_name", newSubject.subject_faculty_name);
    formData.append("Subject_description", newSubject.Subject_description);
    formData.append("course", newSubject.course);
    formData.append("faculty", facultyId);
    if (newSubject.video_url instanceof File) {
      formData.append("video_url", newSubject.video_url);
    }

    axios
      .post(`${baseUrl}app/subject/upload/`, formData, config)
      .then((response) => {
        const addedSubject = response.data; 
        setSubjects((prevSubjects) => [...prevSubjects, addedSubject]); 
        fetchSubjects(currentPage); 
        setNewSubject({
          Subject_name: "",
          subject_faculty_name: "",
          Subject_description: "",
          course: courseId,
          faculty: "",
          video_url: null,
        });
        setFacultyId("");
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("Error adding subject:", error);
      
        setLoading(false);
        close();
      })
  };

  // handle editing a subject
  const handleEditSubject = (subjectId, editedSubject, close, facultyId) => {
    setLoading(true);
  
    const formData = new FormData();
    formData.append("Subject_name", editedSubject.Subject_name);
    formData.append("subject_faculty_name", editedSubject.subject_faculty_name);
    formData.append("Subject_description", editedSubject.Subject_description);
    formData.append("course", editedSubject.course);
    formData.append("faculty", editedSubject.faculty);
    if (editedSubject.video_url) {
      formData.append("video_url", editedSubject.video_url);
    }
  
    axios
      .patch(`${baseUrl}app/subject-edit/${subjectId}/`, formData, config)
      .then((response) => {
        // Update the subject in the state
        setSubjects((prevSubjects) =>
          prevSubjects.map((subject) =>
            subject.id === subjectId ? response.data : subject
          )
        );
        setNewSubject({
          Subject_name: "",
          subject_faculty_name: "",
          Subject_description: "",
          course: courseId,
          faculty: "",
          video_url: null,
        });
        setFacultyId("");
        close();
      })
      .catch((error) => {
        console.error("Error editing subject:", error);
        // Handle the error
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  // delete subject
  const deleteSubject = (subjectId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/subject-delete/${subjectId}/`, config)
      .then((response) => {
        setSubjects((prevSubjects) =>
          prevSubjects.filter((subject) => subject.id !== subjectId)
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting subject:", error);
        setLoading(false);
      });
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setNewSubject({
      ...newSubject,
      [name]: value,
    });
  };

  const handleFileInputChange = (e) => {
    const { name, files } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setNewSubject({
      ...newSubject,
      [name]: files[0],
    });
  };

  // Function to handle faculty change
  const handleFacultyChange = (e) => {
    const selectedFacultyId = e.target.value;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      subject_faculty_name: "",
    }));

    const selectedFaculty = facultyList.find(
      (faculty) => faculty.faculty_id === selectedFacultyId
    );
    setFacultyId(selectedFacultyId);
    setNewSubject({
      ...newSubject,
      faculty: selectedFacultyId,
      subject_faculty_name: selectedFaculty ? selectedFaculty.faculty_name : "",
    });
  };

  // Function to handle course click
  const handleCourseClick = () => {
    onPageChange("course");
  };

  return (
    <div className="main-container">
      <div className="go-back-div">
        <Button className="go-back" onClick={handleCourseClick}>
          Go Back
        </Button>
      </div>
      <Container fluid>
        <div className="subject-screen-container">
          {loading ? (
            <div className="spinner-div">
              <Spinner />
            </div>
          ): subjects.length === 0 ? (
            <p>No subjects available for this course.</p>
          ) : (
            subjects.map((subject) => (
              <SubjectComponent
                key={subject.id}
                id={subject.id}
                Subject_name={subject.Subject_name}
                Subject_description={subject.Subject_description}
                subject_faculty_name={subject.subject_faculty_name}
                video_url={subject.video_url}
                order={subject.order}
                courseId={courseId}
                facultyId={subject.faculty}
                onEditSubject={handleEditSubject}
                onDelete={deleteSubject}
                facultyList={facultyList}
                onFacultyChange={handleFacultyChange}
              />
            ))
          )}
          <Popup
            className="add-popup-container"
            trigger={<button className="btn-add">+ Add Subject Details</button>}
            modal
            nested
          >
            {(close) => (
              <div className="add-course-div">
                <form onSubmit={(e) => handleSubmit(e, close)}>
                  <Box>
                    <div className="heading">Add Subject Details</div>
                    <div className="course-container">
                      <TextField
                        className="textfields"
                        name="Subject_name"
                        label="Name"
                        variant="outlined"
                        size="small"
                        value={newSubject.Subject_name}
                        onChange={handleInputChange}
                        error={!!validationErrors.Subject_name}
                        helperText={validationErrors.Subject_name}
                      />
                      <select
                        name="faculty"
                        className="form-control"
                        value={facultyId}
                        onChange={handleFacultyChange}
                      >
                        <option value="">Select Faculty</option>
                        {facultyList.map((option) => (
                          <option
                            key={option.faculty_id}
                            value={option.faculty_id}
                          >
                            {option.faculty_name}
                          </option>
                        ))}
                      </select>
                      {validationErrors.subject_faculty_name && (
                        <p className="error-text">
                          {validationErrors.subject_faculty_name}
                        </p>
                      )}
                      <TextField
                        className="textfields"
                        name="Subject_description"
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={3}
                        size="small"
                        value={newSubject.Subject_description}
                        onChange={handleInputChange}
                        error={!!validationErrors.Subject_description}
                        helperText={validationErrors.Subject_description}
                      />
                      <label className="add-file-upload">
                        <input
                          className="add-img-file"
                          type="file"
                          name="video_url"
                          onChange={handleFileInputChange}
                        />
                        <MdOutlineFileUpload className="upload-icon" />
                      </label>
                      {validationErrors.video_url && (
                        <p className="error-text">
                          {validationErrors.video_url}
                        </p>
                      )}
                    </div>
                    <div className="submit-banner-button-container">
                      <button type="submit" disabled={loading}>
                        Submit
                      </button>
                      <button type="button" onClick={close}>
                        Cancel
                      </button>
                    </div>
                  </Box>
                </form>
              </div>
            )}
          </Popup>
        </div>
        {totalPages > 1 && (
          <div className="pagination-container">
            <div id="pagination">{renderPaginationControls()}</div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default SubjectScreen;


